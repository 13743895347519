import React, { Fragment } from "react"
import {Layout} from "@components/Layout"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import theme from "@styles/theme"
import Helmet from "react-helmet"
import { Grid } from "semantic-ui-react"

interface Props{
  data: any
}

class ViralLandingPage extends React.Component<Props>{
  render(){ 
    return (
        <Fragment>
            <Helmet defer={false}>
                <script async type="text/javascript" src="https://app.viralsweep.com/vsa-widget-ebf51c-117934.js?sid=117934_661795" defer></script>
            </Helmet>
        
        <Layout version="standard">
                <GenericBannerContainer 
                    padding={{
                        mobile: {
                            top: 100,
                            bottom: 80
                        },
                        desktop: {
                            top: 120,
                            bottom: 100
                        }
                    }} backgroundColor={theme.brand.colors.black+'ed'}>
                    <Grid centered>
                        <Grid.Row>
                            <div id="vsscript_117934_661795"></div>
                        </Grid.Row>
                    </Grid>
                    
                </GenericBannerContainer>
          </Layout>
          </Fragment>
      );
  }
}

export default ViralLandingPage
